.UserTitle{
    text-align: center;
    font-size: 35px;
    padding-top: 50px;
    padding-bottom: 30px;
    font-weight: 450;
}

.UserButton{
    width: 170px;
    margin-top: 10px;
    background-color: #009fe3;
}

.userDialogButton{
    padding: 10px;
}

.userText{
    margin: 0 0 0 10px;
    width: 400px;
}
.userFormControl {
    margin: 20px 0 0 0;
    display: block;

}

.userGroupUserName{
    margin: 0 0 0 10px;
    width: 400px;
}

.userTextPassword{
    margin: 0 0 10px 20px;
    color: #666666;
    font-style: italic;
}

.userDivAccordion{
    width: 50%;
    margin: auto;
}

@media only screen and (max-width: 800px){
    .userDivAccordion{
        width: 95%;
    }

    .userGroupUserName{
        width: auto;
    }

    .userText{
        width: auto;
    }
}

.accordion-button {
    background-color: #999 !important;
    color: #000 !important;
    text-transform: none !important;
}

.accordion-button:focus{
    box-shadow: none !important;
}

.accordion-button:not(.collapsed){
    color: #000 !important;
}

.userAccordionBody{
    background-color: #aaa;
}

.userTableNoBorder{
    border: none;
    margin-bottom: 5px;
    border-collapse: separate;
    border-spacing: 5px;
}

.userTd{
    border: solid #999 1px;
    color: white;
    background-color: #555;
}

.userMainDiv{
    margin-bottom: 100px;
}