.MenuMainDiv{
    background-color: #464949;
    color: white;
    position: fixed;
    top: 0;
    height: 100%;
    width: 200px;
    z-index: 100;
    padding: 10px 0 0 10px;
    overflow: auto;
    border-right: solid #666 1px;
}

.mobileMenu{
    visibility: hidden;
    width: 0;
    height: 0;
}

@media only screen and (max-width: 800px){
    .MenuMainDiv{
        height: 0;
        width: 0;
        visibility: hidden;
    }

    .mobileMenu{
        visibility: visible;
        background-color: #464949;
        position: fixed;
        text-align: center;
        align-content: center;
        bottom: 0;
        height: 50px;
        width: 100%;
        z-index: 100;
        border-top: solid #666 1px;
    }

    .Menubutton{
        width: 60px;
        height: auto;
    }
}

.MenuImg{
    width: 70%;
    height: auto;
    margin-bottom: 20px;
}

.MenuText{
    font-size: 18px;
    padding-left: 10px;
    cursor: pointer;
}

.MenuSubtitle{
    padding: 7px 0 0 5px;
    font-size: 20px;
    color: #a6a6a6;
    cursor: default;
}

#MenuLogin{
    margin-top: 40px;
}