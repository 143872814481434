.loginMainDiv{
    text-align: center;
}

.loginTitle{
    text-align: center;
    font-size: 35px;
    padding-top: 50px;
    padding-bottom: 30px;
    font-weight: 450;
}

.loginInput{
    display: block;
    width: 40%;
    margin: 10px auto 10px;
    padding: 10px;
    font-size: 18px;
    background-color: #555;
    color: white;
    border: none;
}

@media only screen and (max-width: 800px){
    .loginInput{
        width: 90%;
    }
}

.loginInput::-webkit-inner-spin-button{
    -webkit-appearance: none;
}

.loginInput::placeholder{
    color: #999;
}

.loginButton{
    width: 140px;
    margin-top: 10px;
    background-color: #009fe3;
}