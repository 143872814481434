html{
    width: 100%;

}

body{
    width: 100%;
}

.deviceFlexText{
    font-size: 25px;
    margin: 5px 0 0 0;
}

.deviceDisplayedText{
    font-size: 18px;
    margin: 0;
}

.devicesDivTxt{
    margin-top: 15px;
}

.DeviceBackButton, .deviceMapDiv{
    text-align: center;
}

.devicesButton{
    width: 140px;
    margin-top: 10px;
    background-color: #009fe3;
}

.devicesButtonNoMargin{
    width: 140px;
    height: 30px;
    padding: 0;
    background-color: #009fe3;
}

.devicesButtonMap{
    width: 130px;
    margin-top: 10px;
}

.devicesButtonAddDevice{
    width: 200px;
    margin-top: 10px;
    background-color: #009fe3;
}

.devicesFlexboxDivSlider{
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.devicesFlexboxDiv{
    display: flex;
    justify-content: space-around;
}

.devicesTextDevice{
    margin: 0 0 0 10px;
    width: 200px;
}

.deviceBoldSpan{
    font-weight: bold;
    padding-right: 40px;
    width: 145px;
}

@media only screen and (max-width: 800px){
    .devicesFlexboxDiv{
        display: block;
    }

    .devicesTextDevice{
        width: auto;
    }

    .deviceBoldSpan{
        padding-right: 5px;
        width: auto;
    }

    .devicesFlexboxDivLeftDisplay{
        row-gap: 5px;
    }
}

.devicesLegendAcceleration{
    display: flex;
    justify-content: space-around;
    background-color: white;
}

.devicesFlexDivLeft{
    display: flex;
}

.deviceCheckbox{
    width: 20px;
    margin: 5px 5px 0 30px;
}

.leaflet-container {
    height: 800px;
    width: 95%;
    align-content: center;
    margin-top: 30px;
    margin-left: 2.5%;
    margin-bottom: 30px;
    z-index: 0;
}

.deviceAxisLatitude{
    transform: translate(20px, 0);
    user-select: none;
}

.deviceRechartsUserSelection{
    user-select: none;
}

.deviceCustomTooltip{
    background-color: white;
    border: #666 1px solid;
    padding: 0 10px 0 10px;
}

.deviceResetMap{
    z-index: 5000;
    position: absolute;
    right: 5px;
    top : 0;
    padding: 2px;
}

.devicesInput{
    width: 70px;
    height: 35px;
    margin: 5px 5px 0 5px;
    padding: 0 0 0 5px;
    font-size: 18px;
    background-color: #999999;
}

.devicesInput::-webkit-inner-spin-button{
    -webkit-appearance: none;
}

.devicesFlexTextBoundsYaxis{
    margin: 12px 0 0 0;
    font-size: 17px;
}

.devicesFlexboxDivSpaceEvenly{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
    margin-top: 20px;
    align-items: flex-end;
}

.deviceButtonDelete{
    width: 130px;
    margin-top: 10px;
    background-color: #f77;
}

.deviceButtonDelete:hover{
    background-color: #f22;
    color: white;
}

.devicesTitleCrash {
    margin-top: 10px;
    font-size: 28px;
    font-weight: 450;
    text-align: center;
}

.devicesTextError{
    margin: 0 0 0 10px;
    font-size: 20px;
    color: red;
}

.deviceEdit{
    border-top: black solid 1px;
    padding-top: 10px;
}

.deviceBackgroundWhite{
    background-color: white;
    margin-bottom: 10px;
}

.deviceButtonAdd{
    width: 150px;
    margin-top: 10px;
    background-color: #009fe3;
}

.deviceForm{
    border-top: solid black 1px;
    margin-top: 10px;
    padding-top: 10px;
}

#devicePaddingLeft{
    margin-left: 10px;
}

.devicesFlexboxDivLeftDisplay{
    display: flex;
    flex-wrap: wrap;
    max-width: 50%;
}

.devicesFlexboxDivLeftDisplayGroup{
    display: flex;
    flex-wrap: wrap;
    max-width: 50%;
    width: 425px;
}

.devicesFlexboxDivCenter{
    display: flex;
    justify-content: center;
}

.devicePaddingTop{
    padding-top: 5px;
    margin: 0;
}

.deviceDialog{
    background-color: #464949;
    color: white;
}

.deviceDivAdd{
    margin-top: 10px;
    padding-top: 10px;
    border-top: solid black 1px;
}

.deviceSelect{
    background-color: #555;
}

.Mui-focused .MuiOutlinedInput-notchedOutline{
    border: none !important;
}

.deviceInputLabel{
    color: white !important;
    border-radius: 5px;
    background-color: #555;
}

.deviceMenuItem{
    background-color: #aaa !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.deviceDeleteButton{
    color: #f00;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 2px 5px 2px 15px;
}

.deviceDivWarning{
    margin-left: 10px;
    margin-bottom: 40px;
}

.devicesTextWaring{
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.deviceText{
    margin-left: 10px;
}

.devicesimg{
    width: 200px;
    height: 30px;
}

.devicesimg:hover{
    cursor: pointer;
}

.deviceDivMargin{
    margin-bottom: 10px;
}

.deviceUnderline{
    padding-bottom: 10px;
    border-bottom: black solid 2px;
}