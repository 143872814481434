.missionSubTitle{
    font-size: 24px;
    padding-left: 10px;
}

.missionSubmit{
    margin-left: 100px;
}

.missionBackButton{
    text-align: center;
}

.missionButton{
    width: 160px;
    margin-top: 10px;
    background-color: #009fe3;
}

.missionAddButton{
    width: 150px;
    margin-top: 10px;
    background-color: #009fe3;
}

.missionTextDevices{
    margin: 0 0 0 10px;
    width: 250px;
}

.missionDivTxt{
    margin-top: 15px;
}

.missionDivGroups{
    width: 600px;
}

.missionDivTxtFlexbox{
    margin-top: 15px;
    display: flex;
}

.missionFlexboxDiv{
    display: flex;
}

.missionFlexboxDivManagement{
    display: flex;
    height: 40px;
    flex-wrap: wrap;
}

.missionCheckbox{
    margin: 0 5px 10px 10px;
    width: 20px;
    accent-color: #009fe3;
}

.missionFlexText{
    font-size: 18px;
    margin: 0;
}

.missionRedText{
    margin: 0 0 0 10px;
    color: red;
}

.missionText{
    margin: 0 0 0 10px;
    font-size: 18px;
    width: 1000px;
    color: black !important;
}

.missionBoldSpan{
    font-weight: bold;
    padding-right: 40px;
    width: 180px;
}

@media only screen and (max-width: 800px){
    .missionBoldSpan{
        padding-right: 5px;
        width: auto;
    }
}

.missionDivAdd{
    margin-top: 10px;
    padding-top: 10px;
    border-top: solid black 1px;
}

@media (prefers-reduced-motion: no-preference) {
    .missionimgLoading{
        user-select: none;
        margin-top: 10px;
        margin-left: 10px;
        width: 50px;
        height: 50px;
        animation: missionimgLoading-spin infinite 1500ms linear;
    }
}

@keyframes missionimgLoading-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}