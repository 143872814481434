.HomeMainDiv{
    visibility: visible;
    width: 100%;
    height: 100%;
}

.HomeMobileDiv{
    visibility: hidden;
    width: 0;
    height: 0;
}

.homeTitle{
    font-size: 25px;
    font-weight: lighter;
    color:#aaa;
    margin: 10% 0 0 30%;
    z-index: 1;
}

@media only screen and (max-width: 800px){
    .HomeMainDiv{
        width: 0;
        height: 0;
        visibility: hidden;
    }

    .HomeMobileDiv{
        visibility: visible;
        width: 100%;
        height: 100%;
    }

    .homeTitle{
        font-size: 25px;
        font-weight: 450;
        color:#464949;
        margin: 20px 0 0 0;
        text-align: center;
    }

    .HomeFlex{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 100%;
        row-gap: 5px;

    }

    .homeFlexContent{
        width: 30%;
        height: auto;
    }
}

.homeText{
    font-size: 13px;
    z-index: 1;
    color: #6fd0f6;
    margin: 0 0 0 30%;

}

.HomeVideo{
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    object-fit: fill;
    z-index: -1;
}