body {
    background-color: #999 !important;
}

.routerMainDiv{
    display: flex;
}

.RouterContent {
    margin-left: 185px;
    padding-left: 10px;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 800px){
    .RouterContent {
        margin-left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
    }
}

button{
    padding: 1px 5px;
    cursor: pointer;
    text-transform: uppercase !important;
    text-align: center;

}

button:hover {
    background-color: #39f;
    color: white;
}

.baseTitle{
    text-align: center;
    font-size: 35px;
    padding-top: 50px;
    padding-bottom: 30px;
    font-weight: 450;
}

.baseMainDiv{
    padding-bottom: 100px;
}

.baseDivAccordion{
    width: 50%;
    margin: auto;
}

@media only screen and (max-width: 800px){
    .baseDivAccordion{
        width: 95%;
    }
}

.baseAccordionBody{
    background-color: #aaa;
}

.accordion-button {
    background-color: #999 !important;
    color: #000 !important;
    text-transform: none !important;
}

.accordion-button:focus{
    box-shadow: none !important;
}

.accordion-button:not(.collapsed){
    color: #000 !important;
}

.baseTableNoBorder{
    margin-bottom: 5px;
    border-collapse: separate;
    border-spacing: 5px;
}

.baseTd{
    border: solid #999 1px;
    color: white;
    background-color: #555;
}

.baseTdFlex{
    border: solid #999 1px;
    color: white;
    background-color: #555;
    display: flex;
    justify-content: space-between;
}

.baseTr{
    margin-bottom: 5px;
    padding-top: 5px;
}