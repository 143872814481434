.kineticsBoldSpan{
    font-weight: bold;
    padding-right: 40px;
    width: 170px;
}

@media only screen and (max-width: 800px){
    .kineticsBoldSpan{
        padding-right: 5px;
        width: auto;
    }
}

.kineticsButton{
    width: 140px;
    margin-top: 10px;
    background-color: #009fe3;
}

.kineticsEdit{
    border-top: black solid 1px;
    padding-top: 10px;
}