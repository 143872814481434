.alarmText{
    margin: 0 0 0 10px;
    width: 400px;
}

.alarmSubTitle{
    font-size: 24px;
    padding-left: 20px;
    font-weight: 450;
    margin-bottom: 20px;
}

.alarmDivTxt{
    margin-top: 15px;
}

.alarmDivFlex{
    display: flex;
}

.alarmHalfBody{
    width: 50%;
}

.alarmDivAccordion{
    width: 80%;
    margin: auto;
}

.alarmBoldSpan{
    font-weight: bold;
    padding-right: 40px;
    width: 145px;
}

@media only screen and (max-width: 800px){
    .alarmDivFlex{
        display: block;
    }

    .alarmDivAccordion{
        width: 100%;
    }

    .alarmHalfBody{
        margin: 0 auto 40px auto;
        width: 95%;
    }

    .alarmText{
        width: auto;
    }

    .alarmBoldSpan{
        padding-right: 5px;
        width: auto;
    }
}

.alarmButton{
    width: 165px;
    margin-top: 10px;
    background-color: #009fe3;
}

.alarmButtonManage{
    width: 215px;
    margin-top: 10px;
    background-color: #009fe3;
}

.alarmFormControl {
    margin: 50px 0 0 10px;
    display: block;
}

.alarmDivMarginLeft{
    margin-left: 50px;
}

.alarmTableText{
    padding: 0 5px 0 5px;
    margin: 0;
}

.alarmTableTextBold{
    padding: 0 5px 0 5px;
    margin: 0;
    font-weight: bold;
}

.alarmTable{
    margin-bottom: 50px;
    margin-top: 10px;
}

.alarmDivFlexMarginLeft{
    margin-left: 20px;
}

.alarmTextHint{
    margin: 0 0 10px 20px;
    color: #666666;
    font-style: italic;
}

.alarmButtonDelete{
    width: 165px;
    margin-top: 10px;
    background-color: #f77;
}

.alarmButtonDelete:hover{
    background-color: #f22;
    color: white;
}

.alarmEdit{
    border-top: black solid 1px;
    padding-top: 10px;
}

.alarmAdd{
    margin-top: 10px;
    padding-top: 10px;
    border-top: solid black 1px;
}

