.label {
    padding-right: 10px;
    width: 250px;
    font-size: 18px;
}

.formControl {
    padding: 0 10px 10px 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.textArea {
    width: 250px;
    height: 55px;
    background-color: #555;
    color: white;
}

.selectField{
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #555;
    color: white;
    width: 250px;
}

.field{
    background-color: #555;
    color: white;
    width: 250px;
    border: none;
}

.checkBox{
    accent-color: #009fe3;
}

input::file-selector-button {
    padding: 1px 5px;
    cursor: pointer;
    text-transform: uppercase !important;
    text-align: center;
    background-color: #009fe3;
    width: 120px;
}

input::file-selector-button:hover {
    background-color: #39f;
    color: white;
}

@media only screen and (max-width: 800px){

    .label {
        width: auto;
    }

    .textArea {
        width: auto;
        height: auto;
    }


    .selectField{
        width: auto;
    }

    .field{
        width: auto;
    }

    input::file-selector-button {
        width: auto;
    }
}

input[type="date"]::-webkit-datetime-edit-year-field,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field{
    color: white;
}